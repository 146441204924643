import { faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Response } from '../queries/getQuizQuery';

type Props = {
  readonly survey: Response['quiz'];
};

export default function SurveyOverview({ survey }: Props) {
  return (
      <div className="grid grid-cols-2 gap-3">
          <div className="bg-white p-3 shadow-sm rounded-md">
              <strong>Client:</strong> {survey.client.name}
          </div>
          <div className="bg-white p-3 shadow-sm rounded-md">
              <strong>Team name:</strong> {survey.teamName}
          </div>
          <div className="bg-white p-3 shadow-sm rounded-md">
              <strong>Leaders survey link:</strong>{' '}
              <a
                  className="inline-block space-x-2 text-blue-600 hover:text-blue-900"
                  target="_blank"
                  href={survey.leaderLink}
                  rel="noopener noreferrer"
              >
                  <span>Link</span>
                  <FontAwesomeIcon size="xs" icon={faExternalLinkAlt}/>
              </a>
          </div>
          <div className="bg-white p-3 shadow-sm rounded-md">
              <strong>Individuals survey link:</strong>{' '}
              <a
                  className="inline-block space-x-2 text-blue-600 hover:text-blue-900"
                  target="_blank"
                  href={survey.individualLink}
                  rel="noopener noreferrer"
              >
                  <span>Link</span>
                  <FontAwesomeIcon size="xs" icon={faExternalLinkAlt}/>
              </a>
          </div>
      </div>
  );
}
