import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUpdateReportUrlMutation } from "../mutations/updateReportUrl";
import { useGetIndividualReportDataQuery } from './SurveyResponse/queries/getIndividualReportDataQuery';
import { Response } from '../queries/getQuizQuery';

type Props = {
    readonly response: Response['quiz']["responses"][number];
};

export default function DownloadReport({ response }: Props) {
    const [loading, setLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);

    const { data, loading: dataLoading, error } = useGetIndividualReportDataQuery({
        variables: { responseId: response.id },
        skip: response.reportUrl ? true : false,
    });

    const [updateReportUrl, { loading: updateReportUrlLoading }] = useUpdateReportUrlMutation();

    useEffect(() => {
        if (pdfUrl) {
            window.open(pdfUrl, "_blank");
        }
    }, [pdfUrl]);

    const handleGeneratePdf = async () => {
        if (loading || pdfUrl) return;

        setLoading(true);

        // Pobierz dane z zapytania GraphQL
        if (!data || dataLoading || error) {
            console.error("Brak danych lub błąd podczas ładowania danych", error);
            setLoading(false);
            return;
        }

        const { name, profileName, date, textTop, textBottom, results } = data.getIndividualReportData;

        const dataForPdf = {
            name,
            profileName,
            date,
            textTop,
            textBottom,
            results,
        };

        try {
            const pdfApiResponse = await fetch(`${process.env.REACT_APP_PDF_GENERATOR_ENDPOINT}/generate-pdf`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataForPdf),
            });


            const pdfApiResponseObject = await pdfApiResponse.json();

            if (pdfApiResponseObject.success) {
                setPdfUrl(pdfApiResponseObject.fileUrl);


                await updateReportUrl({
                    variables: {
                        id: response.id,
                        reportUrl: pdfApiResponseObject.fileUrl,
                    },
                    onCompleted: () => {
                        console.log("Report URL updated successfully");
                    },
                    onError: (error) => {
                        console.error("Error updating report URL:", error);
                    },
                });
            } else {
                console.error("Failed to generate PDF", pdfApiResponseObject);
            }
        } catch (error) {
            console.error("Error generating PDF", error);
        } finally {
            setLoading(false);
        }
    };

    if (dataLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div>
            {response.reportUrl ? (
                <a href={response.reportUrl}
                   target="_blank"
                   rel="noopener noreferrer"
                   className="flex items-center justify-center space-x-2 p-2 border border-blue-500 rounded-md text-blue-500"
                >
                    <FontAwesomeIcon icon="download" />
                    <span>Download Report</span>
                </a>
            ) : (
                pdfUrl ? (
                    <a
                        href={pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center space-x-2 p-2 border border-blue-500 rounded-md text-blue-500"
                    >
                        <FontAwesomeIcon icon="download" />
                        <span>Download Report</span>
                    </a>
                ) : (
                    <button
                        onClick={handleGeneratePdf}
                        disabled={loading || updateReportUrlLoading}
                        className="flex items-center justify-center space-x-2 p-2 border border-blue-500 rounded-md text-white bg-blue-500"
                    >
                        {loading && !updateReportUrlLoading ? (
                            <>
                                <span>Generating...</span>
                            </>
                        ) : updateReportUrlLoading ? (
                            <>
                                <span>Saving...</span>
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon="download" />
                                <span>Download Report</span>
                            </>
                        )}
                    </button>
                )
            )}
        </div>
    );
}
