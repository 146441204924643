import {
    faExternalLinkAlt,
    faHexagonExclamation,
} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {format} from 'date-fns';
import {useMemo} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import AdminPage from '../components/AdminPage';
import Button from '../components/Button';
import Heading from '../components/Heading';
import List from '../components/List';
import {useConfirm} from '../hooks/usePrompt';
import {useToken} from '../hooks/useToken';
import SurveyOverview from './components/SurveyOverview';
import DownloadReport from './components/DownloadReport';
import DownloadLeaderReport from './components/DownloadLeaderReport';
import {useCloseQuizMutation} from './mutations/closeQuizMutation';
import {useReopenQuizMutation} from './mutations/reopenQuizMutation';
import {useGetQuizQuery} from './queries/getQuizQuery';

type Params = {
    readonly id: string;
};

export function AdminSurveyView() {
    const [token] = useToken();

    const {id} = useParams<Params>();
    const {data, refetch} = useGetQuizQuery({variables: {id: id!}});

    const confirm = useConfirm();

    const [closeQuiz, {loading: closeQuizLoading}] = useCloseQuizMutation({
        variables: {
            id: id!,
        },
        onCompleted: () => refetch(),
    });

    const [reopenQuiz, {loading: reopenQuizLoading}] = useReopenQuizMutation({
        variables: {
            id: id!,
        },
        onCompleted: () => refetch(),
    });

    const reportLink = useMemo(() => {
        return `${process.env.REACT_APP_GRAPHQL_ENDPOINT}/report/${id}?token=${token}`;
    }, [id, token]);

    if (!data) {
        return null;
    }
    const sortedResponses = [...data.quiz.responses].sort((a, b) => {
        if (a.respondentType === 'LEADER' && b.respondentType !== 'LEADER') {
            return -1;
        }
        if (a.respondentType !== 'LEADER' && b.respondentType === 'LEADER') {
            return 1;
        }
        return new Date(b.created).getTime() - new Date(a.created).getTime();
    });
    return (
        <AdminPage
            title={`Survey - ${data.quiz.title}`}
            actions={
                data.quiz.closed ? (
                    <Button
                        onClick={() => confirm('Reopen the survey?', () => reopenQuiz())}
                    >
                        Reopen survey
                    </Button>
                ) : (
                    <Button
                        theme="danger"
                        onClick={() =>
                            confirm('Close the survey? This will make it inaccessible.', () =>
                                closeQuiz(),
                            )
                        }
                    >
                        Close survey
                    </Button>
                )
            }
        >
            {data.quiz.closed && (
                <div className="text-red-600 flex items-center space-x-2">
                    <FontAwesomeIcon icon={faHexagonExclamation}/>
                    <p>This survey is closed and not accessible for completion.</p>
                </div>
            )}

            <SurveyOverview survey={data.quiz}/>

            <Heading
                level={2}
                text={`Responses - ${data.quiz.responses.length}`}
                actions={
                    <div className={"flex gap-10 flex-row"}>
                        <DownloadLeaderReport quiz={data.quiz}/>
                        <a
                            target="_blank"
                            className="flex items-center space-x-2 text-blue-600 hover:text-blue-900"
                            href={reportLink}
                        >
                            <span>Download survey data</span>
                            <FontAwesomeIcon size="xs" icon={faExternalLinkAlt}/>
                        </a>

                    </div>
                }
            />

            <List
                placeholder="No responses yet"
                items={sortedResponses}
                render={(response) => (
                    <div className="flex items-center leading-tight">
                        <p>{response.respondentName ?? 'Anonymous'}</p>
                        <p className="text-gray-600 ml-3">
                            {response.respondentType} &ndash;{' '}
                            {format(response.created, 'do MMM yyyy')}
                        </p>
                        <p className="ml-auto">
                            <DownloadReport response={response}/>
                        </p>
                    </div>
                )}
            />

            <Outlet/>
        </AdminPage>
    );
}
