type Props = {
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly placeholder?: string;
};

export default function TextAreaInput({
                                        placeholder,
                                        value,
                                        onChange,
                                      }: Props) {
  return (
      <textarea
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="bg-white border shadow-sm border-gray-400 rounded-sm px-2 py-1 w-full h-32 resize-none focus:outline-none focus:border-primary"
      />
  );
}
