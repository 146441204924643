import {gql, QueryHookOptions, useQuery} from '@apollo/client';

export const QUERY = gql`
  query GetLeaderReportData($quizId: ID!) {
    getLeaderReportData(quizId: $quizId) {
      teamName
      date
      textTop
      textBottom
      psychologicalSafety{
        acceptance
        riskTolerance
        vulnerability
        overall
      }
      psychologicalSafetyDesc{
        acceptance
        riskTolerance
        vulnerability
        overall
      }
      results{
        individualsInYourTeam
        perceptionOfLeadership
        groupDynamics
        teamCulture
      }
      teamStyles{
        name
        percent
      }
      teamStyleDesc
    }
  }
`;

export type Variables = {
    quizId: string;
};

export type Response = {
    readonly getLeaderReportData: {
        readonly teamName: string;
        readonly date: string;
        readonly psychologicalSafety: {
            readonly acceptance: number;
            readonly riskTolerance: number;
            readonly vulnerability: number;
            readonly overall: number;
        };
        readonly psychologicalSafetyDesc: {
            readonly acceptance: string;
            readonly riskTolerance: string;
            readonly vulnerability: string;
            readonly overall: string;
        };
        readonly textTop: string;
        readonly textBottom: string;
        readonly results: {
            readonly individualsInYourTeam: string[];
            readonly perceptionOfLeadership: string[];
            readonly groupDynamics: string[];
            readonly teamCulture: string[];
        };
        readonly teamStyles: {
            readonly name: string;
            readonly percent: number;
        }[];
        readonly teamStyleDesc: string
    };
};

export const useGetLeaderReportDataQuery = (
    options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
