import {
  faBars,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayMoveImmutable } from 'array-move';
import { shuffle } from 'fast-shuffle';
import { useCallback, useMemo } from 'react';
import { ReactSortable } from 'react-sortablejs';

type Props = {
  readonly value: readonly string[];
  readonly onChange: (value: readonly string[]) => void;
  readonly options: readonly {
    readonly value: string;
    readonly text: string;
  }[];
};

export default function OrderInput({ value, onChange, options }: Props) {
  const orderedOptions = useMemo(() => {
    // Indicates user has not selected any values yet.
    if (value.length === 0) {
      return shuffle(
        options.map((option) => ({ id: option.value, ...option })),
      );
    }

      return value
          .map((key) => options.find((option) => option.value === key))
          .filter((option): option is { value: string; text: string } => option !== undefined) // TypeScript type guard
          .map((option) => ({ id: option.value, ...option }));
  }, [options, value]);

  const handleMove = useCallback(
    (from: number, to: number) => {
      onChange(
        arrayMoveImmutable(
          orderedOptions.map((opt) => opt.value),
          from,
          to,
        ),
      );
    },
    [orderedOptions, onChange],
  );

  return (
    <div>
      <ReactSortable
        animation={200}
        delay={200}
        list={orderedOptions}
        setList={(value) => {
          if (
            !value.every(
              (entry, index) => orderedOptions[index].id === value[index].id,
            )
          ) {
            onChange(value.map((opt) => opt.value));
          }
        }}
      >
        {orderedOptions.map((option, index) => (
          <div
            key={option.id}
            className="flex items-center space-x-5 mb-2 last:mb-0 cursor-move"
          >
            <div className="flex-shrink-0">{index + 1}</div>
            <div className="flex-grow flex items-center leading-tight bg-white bg-opacity-10 px-3 py-2 rounded-sm space-x-3">
              <div className="flex-shrink-0 flex items-center justify-center">
                <FontAwesomeIcon icon={faBars} />
              </div>
              <div className="flex-grow">{option.text}</div>
              <div className="flex-shrink-0 flex items-center space-x-2">
                <button
                  disabled={index === 0}
                  onClick={() => handleMove(index, index - 1)}
                  className="flex items-center justify-center bg-white rounded-sm w-8 h-8 text-black hover:bg-opacity-100 bg-opacity-80 disabled:bg-opacity-10 disabled:cursor-not-allowed"
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </button>
                <button
                  disabled={index === orderedOptions.length - 1}
                  onClick={() => handleMove(index, index + 1)}
                  className="flex items-center justify-center bg-white rounded-sm w-8 h-8 text-black hover:bg-opacity-100 bg-opacity-80 disabled:bg-opacity-10 disabled:cursor-not-allowed"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </ReactSortable>
    </div>
  );
}
