import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const QUERY = gql`
  query GetIndividualReportData($responseId: ID!) {
    getIndividualReportData(responseId: $responseId) {
      name
      profileName
      date
      textTop
      textBottom
      results
    }
  }
`;

export type Variables = {
  responseId: string;
};

export type Response = {
  readonly getIndividualReportData: {
    readonly name: string;
    readonly profileName: string;
    readonly date: string;
    readonly textTop: string;
    readonly textBottom: string;
    readonly results: readonly string[];
  };
};

export const useGetIndividualReportDataQuery = (
    options?: QueryHookOptions<Response, Variables>,
    ) => useQuery<Response, Variables>(QUERY, options);
