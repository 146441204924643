import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren } from 'react';
import OverlayPortal from './OverlayPortal';

type Props = PropsWithChildren<{
  readonly heading?: string;
  readonly onClose: () => void;
}>;

export default function Modal({ heading, children, onClose }: Props) {
  return (
    <OverlayPortal>
      <div className="fixed inset-0 flex items-center justify-center z-20 bg-black bg-opacity-20" >
        <div className="relative bg-white w-full max-w-2xl rounded-sm shadow-xl">
          <button
            onClick={onClose}
            className="absolute top-3 right-3 w-4 h-4 flex items-center justify-center"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          {heading && (
            <div className="p-5 border-b border-gray-200">
              <h4 className="font-bold">{heading}</h4>
            </div>
          )}

          <div className="p-5 overflow-y-auto">{children}</div>
        </div>
      </div>
    </OverlayPortal>
  );
}
