import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation createQuiz($input: CreateQuizInput!) {
    createQuiz(input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly input: {
    readonly title: string;
    readonly introduction: string;
    readonly teamName: string;
    readonly introductionLeader: string;
    readonly client: string;
    readonly template: string;
  };
};

export type Response = {
  readonly createQuiz: {
    readonly id: string;
  };
};

export const useCreateQuizMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
