import clsx from 'clsx';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  readonly theme?: 'primary' | 'general' | 'danger';
  readonly disabled?: boolean;
  readonly loading?: boolean;
  readonly onClick: () => void;
}>;

export default function Button({
  theme = 'general',
  disabled = false,
  loading = false,
  children,
  onClick,
}: Props) {
  return (
    <button
      disabled={disabled || loading}
      className={clsx(
        'text-sm px-3 py-2 rounded-md shadow-sm font-bold tracking-wide',
        {
          'bg-primary text-primary-dark': theme === 'primary',
          'bg-red-700 text-amber-50': theme === 'danger',
          'bg-white bg-opacity-50 border border-gray-300 text-gray-600 hover:shadow-md hover:border-gray-400 hover:text-gray-800':
            theme === 'general',
          'opacity-50': disabled || loading,
        },
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
