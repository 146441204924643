import { useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminPage from '../components/AdminPage';
import Button from '../components/Button';
import TextInput from '../components/input/TextInput';
import TextAreaInput from '../components/input/TextAreaInput';
import List from '../components/List';
import Modal from '../components/Modal';
import { useCreateQuizMutation } from './mutations/createQuizMutation';
import { useGetClientQuery } from './queries/getClientQuery';

type Params = {
  readonly id: string;
};
const defaultIntroduction = 'Welcome to the PanoramaTeams Culture Assessment.\n' +
    '\n' +
    'Panorama is an insights tool designed to uncover the cultural influences and expectations active inside your team.\n' +
    '\n' +
    'This survey has two parts. It takes approximately 10 minutes to complete. We encourage you to take as much time as you need to accurately complete each section.\n';

export function AdminClientView() {
  const [newSurveyTitle, setNewSurveyTitle] = useState('');
  const [newSurveyIntroduction, setNewSurveyIntroduction] = useState(defaultIntroduction);
  const [newSurveyTeamName, setNewSurveyTeamName] = useState('');
  const [newSurveyIntroductionLeader, setNewSurveyIntroductionLeader] = useState(defaultIntroduction);

  const [newSurveyTemplateId, setNewSurveyTemplateId] = useState(
    // Todo: Hard-coded for now, but will want to make this dynamic eventually.
    // Doesn't add much immediate value though, so leaving it for now.
    '678c72be-32a3-47cd-970b-69c9c4ca05b6',
  );
  const [showCreateSurveyModal, setShowCreateSurveyModal] = useState(false);

  const { id } = useParams<Params>();
  const { data, refetch } = useGetClientQuery({ variables: { id: id! } });

  const [createQuiz, { loading: createQuizLoading }] = useCreateQuizMutation({
    variables: {
      input: {
        title: newSurveyTitle,
        introduction: newSurveyIntroduction,
        introductionLeader: newSurveyIntroductionLeader,
        teamName: newSurveyTeamName,
        client: id!,
        template: newSurveyTemplateId,
      },
    },
    onCompleted: (data) => {
      setNewSurveyTitle('');
      setNewSurveyIntroduction('');
      setShowCreateSurveyModal(false);
      setNewSurveyIntroductionLeader('');
      setNewSurveyTeamName('');

      return refetch();
    },
  });

  if (!data) {
    return null;
  }

  return (
    <AdminPage
      title={`Clients - ${data.client.name}`}
      actions={
        <Button onClick={() => setShowCreateSurveyModal(true)}>
          Create Survey
        </Button>
      }
    >
      <div>
        <List
          items={data.quizzes}
          render={(quiz) => (
            <div>
              {quiz.title}{' '}
              {quiz.closed && (
                <span className="text-red-500 text-sm">(Closed)</span>
              )}
            </div>
          )}
          link={(quiz) => `/admin/surveys/${quiz.id}`}
        />
      </div>

      {showCreateSurveyModal && (
        <Modal
          heading="Create New Survey"
          onClose={() => setShowCreateSurveyModal(false)}
        >
          <div className="space-y-2">
            <TextInput
                placeholder="Title"
                value={newSurveyTitle}
                onChange={setNewSurveyTitle}
            />
            <TextInput
                placeholder="Team Name"
                value={newSurveyTeamName}
                onChange={setNewSurveyTeamName}
            />
            <div>
              <label>Introduction for individuals</label>
              <TextAreaInput
                  placeholder="Introduction for individuals"
                  value={newSurveyIntroduction || defaultIntroduction}
                  onChange={setNewSurveyIntroduction}
              />
            </div>

            <div>
              <label>Introduction for leaders</label>
              <TextAreaInput
                  placeholder="Introduction for leaders"
                  value={newSurveyIntroductionLeader || defaultIntroduction}
                  onChange={setNewSurveyIntroductionLeader}
              />
            </div>

              <Button onClick={createQuiz} loading={createQuizLoading}>
                Create
              </Button>
            </div>
        </Modal>
        )}
    </AdminPage>
  );
}
